<template>
  <van-popup v-model="showPicker" round position="bottom">
    <van-picker
      show-toolbar
      :columns="columns"
      @cancel="showPicker = false"
      @confirm="onConfirm"
    />
  </van-popup>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  computed: {
    showPicker: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  },
  methods: {
    onConfirm(v) {
      this.showPicker = false;
      this.$emit("confirm", v);
    }
  }
};
</script>
